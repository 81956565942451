import { Controller } from 'stimulus';
import { fetch } from '../fetch';
import { hideIcon, showIcon } from '../controllerIcon';

export default class extends Controller {
	static targets = [
		'itemType',
		'itemId',
		'color',
		'conditionUsed',
		'conditionNew',
		'suggestions',
		'suggestionsStatus',
		'storageId',
	];

	connect() {
		this.update();
	}

	update() {
		if (this.itemType === '' || this.itemId === '') {
			return;
		}

		this.suggestionsTarget.innerHTML = '';

		const query = new URLSearchParams();

		if (this.color !== '') {
			query.set('color', this.color);
		}

		if (this.condition !== '') {
			query.set('condition', this.condition);
		}

		showIcon(this.suggestionsStatusTarget, '⌛️');

		fetch(`/inventory_storage/suggest/${this.itemType}/${this.itemId}?${query.toString()}`)
			.then(payload => {
				const storages = payload.data;

				hideIcon(this.suggestionsStatusTarget);

				this.suggestionsTarget.innerHTML = storages
					.map(storage => (`<option value="${storage.storage_id}" />`))
					.join('');
			})
			.catch(err => {
				showIcon(this.suggestionsStatusTarget, '❌', err.message);
			})
		;
	}

	select(e) {
		e.preventDefault();

		this.storageIdTarget.value = e.target.dataset.id;
		this.storageIdTarget.focus();

		this.suggestionsTarget.innerHTML = '';
	}

	get itemType() {
		return this.itemTypeTarget.value;
	}

	get itemId() {
		return this.itemIdTarget.value;
	}

	get color() {
		return this.colorTarget.value;
	}

	get isConditionUsed() {
		return this.conditionUsedTarget.checked;
	}

	get isConditionNew() {
		return this.conditionNewTarget.checked;
	}

	get condition() {
		if (this.isConditionUsed) {
			return 'USED';
		}

		if (this.isConditionNew) {
			return 'NEW';
		}

		return '';
	}
}
